import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classnames from 'classnames';

import styles from './Modal.module.scss';

ReactModal.setAppElement('#___gatsby');

export default function Modal({
    children,
    isOpen,
    onRequestClose,
    title,
    subtitle,
    style,
}) {
    const customStyles = {
        overlay: {
            zIndex: 9,
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            borderRadius: 0,
            border: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            ...(style || {}),
        },
    };

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    return (
        <Fragment>
            <ReactModal
                isOpen={isOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={onRequestClose}
                style={customStyles}
                contentLabel={title}
                closeTimeoutMS={2000}
            >
                <span
                    className={classnames(styles.border, styles.borderTopLeft)}
                />
                <span
                    className={classnames(styles.border, styles.borderTopRight)}
                />
                <span
                    className={classnames(
                        styles.border,
                        styles.borderBottomLeft
                    )}
                />
                <span
                    className={classnames(
                        styles.border,
                        styles.borderBottomRight
                    )}
                />
                <span className={classnames(styles.border, styles.borderTop)} />
                <span
                    className={classnames(styles.border, styles.borderBottom)}
                />
                {title && <h2 className={styles.title}>{title}</h2>}
                {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
                {children}
            </ReactModal>
        </Fragment>
    );
}

Modal.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    isOpen: PropTypes.bool,
    style: PropTypes.object,
    onRequestClose: PropTypes.func,
};

Modal.defaultProps = {
    children: null,
    isOpen: false,
    onRequestClose() {},
};
