module.exports = {
    mobile: '(minWidth: 400px)',
    Mobile: '@media (minWidth: 400px)',
    phablet: '(minWidth: 550px)',
    Phablet: '@media (minWidth: 550px)',
    tablet: '(minWidth: 750px)',
    Tablet: '@media (minWidth: 750px)',
    desktop: '(minWidth: 1000px)',
    Desktop: '@media (minWidth: 1000px)',
    hd: '(minWidth: 1200px)',
    Hd: '@media (minWidth: 1200px)',
    maxWidth: 76,
};
