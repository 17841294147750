import React from 'react';
import PropTypes from 'prop-types';

export default function TipList({ tips }) {
    if (!tips) {
        return null;
    }

    return (
        <ul>
            {tips.map((tip, i) => {
                return <li key={i}>{tip.text}</li>;
            })}
        </ul>
    );
}

TipList.propTypes = {
    tips: PropTypes.array,
};

TipList.defaultProps = {
    tips: [],
};
