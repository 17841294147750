import React, { Component } from 'react';
import PropTypes from 'prop-types';

import presets from '../utils/presets';

export default class Container extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        style: PropTypes.object,
        className: PropTypes.string,
        tagName: PropTypes.string,
    };

    static defaultProps = {
        tagName: 'div',
    };

    render() {
        const { children, className, tagName, style } = this.props;
        const props = {
            style: {
                maxWidth: `${presets.maxWidth}rem`,
                margin: '0 auto',
                position: 'relative',
                paddingLeft: '10px',
                paddingRight: '10px',
                [presets.Tablet]: {
                    paddingBottom: '1.5em',
                },
                ...style,
            },
            className: className,
        };

        return React.createElement(tagName, props, children);
    }
}
